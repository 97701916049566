
body {
  color: #263238;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.content-paper {
  margin: 20px 12px;
  padding: 30px 50px;
  position: relative;
  box-shadow: 0 10px 20px rgb(0 0 0 / 19%), 0 6px 6px rgb(0 0 0 / 23%);
  border-radius: 8px;
  background-color: #ffffff;
}

@media (max-width: 1279.95px) {
  .content-paper {
    padding: 25px 30px;
  }
}
